import { cn } from "@/lib/utils";

interface TeaserTagsProps {
  children: React.ReactNode;
  className?: string;
}

const TeaserTags: React.FC<TeaserTagsProps> = ({ className, children }) => (
  <div
    className={cn("flex flex-col items-start gap-1.5 laptop:gap-2", className)}
  >
    {children}
  </div>
);

export default TeaserTags;
