import { useMediaQuery } from "@/app/_hooks/useMediaQuery";
import { Tag } from "@/app/_components/tags";
import { cn } from "@/lib/utils";

interface SolarTagProps {
  children: React.ReactNode;
  className?: string;
}

const TeaserTag: React.FC<SolarTagProps> = ({ children, className }) => {
  const isLaptop = useMediaQuery("(min-width: 1024px)");

  return (
    <Tag
      className={cn(
        "m-0 bg-gray-50/80 text-text backdrop-blur-sm transition-all duration-400 ease-out laptop:bg-gray-50/[0.86] group-hover:laptop:bg-primary-100",
        className,
      )}
      icon="check"
      iconClassName="text-primary-700"
      size={isLaptop ? "large" : "small"}
    >
      {children}
    </Tag>
  );
};

export default TeaserTag;
